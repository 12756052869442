import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import Shipping from '../../Assets/images/vishal-jogiya-img/free-shipping.png';
import Money from '../../Assets/images/vishal-jogiya-img/pound.png';
import online from '../../Assets/images/vishal-jogiya-img/online-support.png';
import flexible from '../../Assets/images/vishal-jogiya-img/flexible-payment.png';

const AdvantageOfVj = () => {
  const advantdata = [
    {
      img: Shipping,
      heading: 'Free Shipping',
      title: 'Free Shipping for orders over £130',
    },
    {
      img: Money,
      heading: 'Money Guarantee',
      title: 'Within 30 days for an exchange',
    },
    // {
    //     img: online,
    //     heading: "24/7 Online Support",
    //     title: "24 hours a day, 7 days a week"
    // },
    {
      img: flexible,
      heading: 'Flexible Payment',
      title: 'Pay with Multiple Credit Cards',
    },
  ];

  var settingsAdvantage = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="vj_advantage_section">
        <Container className="vj_custome_container">
          <div className="vj_ad_bg_color">
            <Row>
              <Col sm={12} md={12} lg={12}>
                <div className="vj_home_heading">
                  <h2 className="text-white">Advantages Of Vishal Jogiya</h2>
                  <p className="text-white">
                    We’ll be available for you anytime and anywhere
                  </p>
                </div>
                <div className="vj_adv_list vj_ad_desktop_view">
                  <ul>
                    {advantdata.map((item) => (
                      <li>
                        <div>
                          <Image
                            src={item.img}
                            alt={item.title + 'Image'}
                            className="img-fluid"
                          />
                          <h6>{item.heading}</h6>
                          <p>{item.title}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="vj_ad_mobile_view">
                  <Slider
                    {...settingsAdvantage}
                    className="vj_advantage_slider"
                  >
                    {advantdata.map((item) => (
                      <div>
                        <div className="vj_advantage_slider_inner_box">
                          <Image
                            src={item.img}
                            alt={item.title + 'Image'}
                            className="img-fluid"
                          />
                          <h6>{item.heading}</h6>
                          <p>{item.title}</p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AdvantageOfVj;
