import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../../Assets/css/home.css";

const Insta = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={12} className='pt-5 pb-3'>
                        <div className='vj_home_heading'>
                            <h2>Our Social Media</h2>
                            <p>Follow us to get inspired <a href='https://www.instagram.com/vjogiya/' className='text-dark'><strong>@vjogiya</strong></a></p>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* <div className="elfsight-app-368122dd-eb07-4a5c-b0a6-014ed3804162"></div> */}
            <div>
                <iframe src="https://instagram.demobw.live/vishal-jogiya/" frameborder="0" width="100%" class="insta-iframe"></iframe>
            </div>
        </>
    )
}

export default Insta;