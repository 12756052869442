import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import CustomImg from "../../Assets/images/vishal-jogiya-img/custom-design.png"
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const CustomDesign = () => {
    const history = useHistory();
    return (
        <>
            <div className='vj_custom_jewellery_section'>
                <Container className='vj_custome_container'>
                    <Row>

                        <Col sm={12} md={6} lg={6} className='vj_fine_text_box_outer vj_custom_order1'>
                            <div className='vj_fine_text_box'>
                                <h2>Custom Design</h2>
                                <p>Custom Jewellery is a testament to personal
                                    style and individuality, where dreams are
                                    transformed into wearable art.  </p>
                                <button className='vj_btn_black' onClick={() => history.push("/custom-design")}>Explore Now</button>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6} className='vj_custom_order2'>
                            <div className='vj_fine_img_box'>
                                <Image src={CustomImg} alt="Finest Image" className='img-fluid' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default CustomDesign