import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import FineImg from "../../Assets/images/vishal-jogiya-img/finestjewellery.png"
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const FinestJewellery = () => {
    const history = useHistory();
    return (
        <>
            <div className='vj_finest_jewellery_section'>
                <Container className='vj_custome_container  '>
                    <Row>
                        <Col sm={12} md={7} lg={7}>
                            <div className='vj_fine_img_box'>
                                <Image src={FineImg} alt="Finest Image" className='img-fluid' />
                            </div>
                        </Col>
                        <Col sm={12} md={5} lg={5} className='vj_fine_text_box_outer'>
                            <div className='vj_fine_text_box'>
                                <h2>Finest Jewellery</h2>
                                <p>Fine jewellery, a timeless expression of elegance
                                    and luxury, crafted with meticulous attention to detail
                                    and using only the finest materials.</p>
                                <button className='vj_btn_black' onClick={() => history.push("/jewelry/fine-jewelry")}>Shop Jewellery</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default FinestJewellery