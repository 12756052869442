import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import ring from '../../Assets/images/vishal-jogiya-img/collection-img/ring.png'
import pendants from '../../Assets/images/vishal-jogiya-img/collection-img/pendants.png'
import earrings from '../../Assets/images/vishal-jogiya-img/collection-img/earrings.png'
import necklaces from '../../Assets/images/vishal-jogiya-img/collection-img/necklaces.png'
import bracelets from '../../Assets/images/vishal-jogiya-img/collection-img/bracelets.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Slider from 'react-slick'

const ShopByCollection = () => {
    const history = useHistory();

    const ringSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const collectionData = [
        {
            id: 1,
            tittle: "Rings",
            collectionImg: ring,
            linkurl: "/jewelry/fashion-rings"
        },
        {
            id: 1,
            tittle: "Pendants",
            collectionImg: pendants,
            linkurl: "/jewelry/pendants"
        },
        {
            id: 1,
            tittle: "Earrings",
            collectionImg: earrings,
            linkurl: "/jewelry/earrings"
        },
        {
            id: 1,
            tittle: "Bracelets",
            collectionImg: bracelets,
            linkurl: "/jewelry/bracelets"
        },
        {
            id: 1,
            tittle: "Necklaces",
            collectionImg: necklaces,
            linkurl: "/jewelry/necklaces"
        },
    ]
    return (
        <>
            <div className='vj_shop_by_collection_sction'>
                <Container className='vj_custome_container'>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='vj_home_heading'>
                                <h2>Shop By Collection</h2>
                                <p>Find Exactly What You Need</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='vj_collection_desktop_view'>
                        <Col sm={12} md={12} lg={12}>
                            <ul className='p-0 list-unstyled vj_collection_list'>
                                {collectionData.map((item) =>
                                    <li onClick={() => history.push(item.linkurl)}>
                                        <div>
                                            <Image src={item.collectionImg} alt="ring img" className="img-fluid" />
                                            <h6>{item.tittle}</h6>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </Col>
                    </Row>
                    <Row className='vj_collection_mobile_view d-lg-none'>
                        <Col sm={12} md={12} lg={12}>
                            <div>
                                <Slider {...ringSettings} className='vj_Collection_slider'>
                                    {collectionData.map((item) => <div className='vj_collection_list'>

                                        <div onClick={() => history.push(item.linkurl)}>
                                            <div className='text-center'>
                                                <Image src={item.collectionImg} alt="ring img" className="img-fluid" />
                                                <h6>{item.tittle}</h6>
                                            </div>
                                        </div>

                                    </div>
                                    )}
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ShopByCollection