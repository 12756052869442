import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Slider from 'react-slick';
import Ring from "../../Assets/images/vishal-jogiya-img/best-seller-img/b-ring.png";
import Necklace from "../../Assets/images/vishal-jogiya-img/best-seller-img/b-necklace.png";
import Pendant from "../../Assets/images/vishal-jogiya-img/best-seller-img/b-pendant.png";
import Earing from "../../Assets/images/vishal-jogiya-img/best-seller-img/b-earing.png";
import ArrowlLeft from "../../Assets/images/vishal-jogiya-img/arrow-left.png";
import ArrowRight from "../../Assets/images/vishal-jogiya-img/arrow-right.png";

const BestSeller = () => {
    const bestSellerData = [
        {
            title: "Twisted Shank Engagement Ring",
            img: Ring,
            price: "€7,950",
            url: "#"
        },
        {
            title: "Traditional Graduated Necklace",
            img: Necklace,
            price: "€7,950",
            url: "#"
        },
        {
            title: "Gold Diamond Earrings",
            img: Earing,
            price: "€7,950",
            url: "#"
        },
        {
            title: "Gold Solitaire Pendant",
            img: Pendant,
            price: "€7,950",
            url: "#"
        },
        {
            title: "Traditional Graduated Necklace",
            img: Necklace,
            price: "€7,950",
            url: "#"
        },

    ]

    var settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: <img src={ArrowlLeft} alt="left arrow Img" className='img-fluid' />,
        nextArrow: <img src={ArrowRight} alt="right arrow Img" className='img-fluid' />,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className='vj_best_seller_section'>
                <Container className='vj_custome_container'>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='vj_home_heading'>
                                <h2>Best Sellers</h2>
                                <p>Join the Trend</p>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} className='p-0'>
                            <Slider {...settings} className='vj_best_seller_slider'>
                                {bestSellerData.map((dataItem) =>
                                    <div className='vj_best_seller_box_text_and_img_outer'>
                                        <div className='vj_best_seller_box_text_and_img'>
                                            <div className='vj_best_sel_img_bopx'>
                                                <Image src={dataItem.img} className='img-fluid' />
                                            </div>
                                            <p>{dataItem.title}</p>
                                            {/* <h6>{dataItem.price}</h6> */}
                                        </div>
                                    </div>
                                )}
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default BestSeller