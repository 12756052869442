import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Slider from 'react-slick';
import Rating from '@mui/material/Rating';
import ArrowlLeft from "../../Assets/images/vishal-jogiya-img/arrow-left.png";
import ArrowRight from "../../Assets/images/vishal-jogiya-img/arrow-right.png";
import GoogleImg from '../../Assets/images/google-review-img.png'


const HappyCustomers = () => {


    const reviewData = [
        {
            reviewText: "OK so I've been going to Vishal for over 10 years and the thing that keeps me coming back is the high quality craftsmanship, professionalism and most of all, the service!",
            reviewName: "Tino B",
            reviewDate: "a month ago",
        },
        {
            reviewText: "Vishal is a fantastic guy, real pleasure to work with. Recently purchased a custom made wedding band and custom necklace. The finish, quality and design was perfect!",
            reviewName: "Neel Dhokia",
            reviewDate: "a month ago",
        },
        {
            reviewText: "Vishal helped to design and create my bespoke engagement ring. His commitment to detail and patience during the process was first class and much appreciated.",
            reviewName: "Pooja Raval",
            reviewDate: "2 months ago",
        },
        {
            reviewText: "Vishal Jogiya is the best in the business, our bespoke designed engagement ring and wedding band were nothing short of perfect. As for Vishal himself, ",
            reviewName: "Margil Patel",
            reviewDate: "2 months ago",
        },
        {
            reviewText: "Great to meet you Vishal, excellent customer service, warmth and very happy with the added guidance, knowledge and your honesty.",
            reviewName: "Daxa Patel",
            reviewDate: "a month ago",
        },
        {
            reviewText: "Bought the most beautiful yellow 18k gold wedding bands here. The finish is absolutely astounding and you won't be able to find anything better in Leicester!",
            reviewName: "Elfrat",
            reviewDate: "4 months ago",
        },
    ]



    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        prevArrow: <img src={ArrowlLeft} alt="left arrow Img" className='img-fluid' />,
        nextArrow: <img src={ArrowRight} alt="right arrow Img" className='img-fluid' />,
        slidesToShow: 4,
        slidesToScroll: 1,
        focusOnSelect: true,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 810,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className='vj_happy_customers_section'>
                <Container className='vj_custome_container '>
                    <Row>
                        <Col sm={12} md={12} lg={12} className='pt-5 pb-3'>
                            <div className='vj_home_heading'>
                                <h2 >Our Happy Customers</h2>
                                <p className='w-100'>Words of Praise</p>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} className='p-0'>
                            <Slider {...settings2} className='vj_slider_happy_customer'>
                                {reviewData.map((data) =>
                                    <div className='vh_happy_inner_outer'>
                                        <div className='vh_happy_inner'>
                                            <p><span>{data.reviewText + " ..."}</span><span> <a href='https://www.google.com/search?gs_ssp=eJzj4tZP1zcsyzKtKrTIMmC0UjWoMLEwNzczNEgzMTaxNE5KTLMyqDA1MUwzMzczNTY3MzZONkry4i3LLM5IzFHIyk_PrEwEAGpgEyU&q=vishal+jogiya&oq=vishal+jogiya&aqs=chrome.1.0i355i512j46i175i199i512j0i512l2j0i22i30j69i65j69i60l2.9935j0j7&sourceid=chrome&ie=UTF-8#lrd=0x4877610f43493baf:0x541f676537633c2b,1,,,,' target='_blank' className='vj_happ_read_more'> Read More ...</a></span></p>
                                            <Rating name="read-only" value={5} readOnly />
                                            <Image src={GoogleImg} alt="Google Image" onClick={() => window.open("https://www.google.com/search?gs_ssp=eJzj4tZP1zcsyzKtKrTIMmC0UjWoMLEwNzczNEgzMTaxNE5KTLMyqDA1MUwzMzczNTY3MzZONkry4i3LLM5IzFHIyk_PrEwEAGpgEyU&q=vishal+jogiya&oq=vishal+jogiya&aqs=chrome.1.0i355i512j46i175i199i512j0i512l2j0i22i30j69i65j69i60l2.9935j0j7&sourceid=chrome&ie=UTF-8#lrd=0x4877610f43493baf:0x541f676537633c2b,1,,,,", "_blank")} />
                                            <div className='vj_hap_client_name_date'>
                                                <h6>{data.reviewName}</h6>
                                                <p>{data.reviewDate}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default HappyCustomers