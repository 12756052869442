import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import round from "../../Assets/images/home/diamond-round.png";
import RoundShape from "../../Assets/images/diamond-shape/Diamond_Round.svg";
import princess from "../../Assets/images/diamond-shape/princess.svg";
import cushion from "../../Assets/images/diamond-shape/cushion.svg";
import emerald from "../../Assets/images/diamond-shape/emerald.svg";
import oval from "../../Assets/images/diamond-shape/oval.svg";
import radiant from "../../Assets/images/diamond-shape/radiant.svg";
import asscher from "../../Assets/images/diamond-shape/asscher.svg";
import marquise from "../../Assets/images/diamond-shape/marquise.svg";
import heart from "../../Assets/images/diamond-shape/heart.svg";
import pear from "../../Assets/images/diamond-shape/pear.svg";

import roundImg from "../../Assets/images/home/diamond-shapes-img/Round.webp";
import princessImg from "../../Assets/images/home/diamond-shapes-img/Princess.webp";
import cushionImg from "../../Assets/images/home/diamond-shapes-img/Cushion.webp";
import emeraldImg from "../../Assets/images/home/diamond-shapes-img/Emerald.webp";
import ovalImg from "../../Assets/images/home/diamond-shapes-img/Oval.webp";
import radiantImg from "../../Assets/images/home/diamond-shapes-img/Radiant.webp";
import asscherImg from "../../Assets/images/home/diamond-shapes-img/Asscher.webp";
import marquiseImg from "../../Assets/images/home/diamond-shapes-img/Marquise.webp";
import heartImg from "../../Assets/images/home/diamond-shapes-img/Heart.webp";
import pearImg from "../../Assets/images/home/diamond-shapes-img/Pear.webp";

import ArrowlLeft from "../../Assets/images/vishal-jogiya-img/arrow-left.png";
import ArrowRight from "../../Assets/images/vishal-jogiya-img/arrow-right.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useHistory } from 'react-router-dom';
function ShopDiamondShape() {
    var history = useHistory();

    const shopbyshape = (value) => {
        var data1 = {
            shape: value,
        }
        sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
        history.push('/diamonds');
        window.scrollTo(0, 0);
    }
    const DiamondShapData = [
        {
            shapName: "Round",
            shapImgMain: roundImg,
            shapImgHover: RoundShape,
            shapUrl: "/diamonds"
        },
        {
            shapName: "Princess",
            shapImgMain: princessImg,
            shapImgHover: princess,
            shapUrl: "/diamonds"
        },
        {
            shapName: "Cushion",
            shapImgMain: cushionImg,
            shapImgHover: cushion,
            shapUrl: "/diamonds"
        },
        {
            shapName: "Emerald",
            shapImgMain: emeraldImg,
            shapImgHover: emerald,
            shapUrl: "/diamonds"
        },
        {
            shapName: "Oval",
            shapImgMain: ovalImg,
            shapImgHover: oval,
            shapUrl: "/diamonds"
        },
        {
            shapName: "Radiant",
            shapImgMain: radiantImg,
            shapImgHover: radiant,
            shapUrl: "/diamonds"
        },
        {
            shapName: "Asscher",
            shapImgMain: asscherImg,
            shapImgHover: asscher,
            shapUrl: "/diamonds"
        },
        {
            shapName: "Marquise",
            shapImgMain: marquiseImg,
            shapImgHover: marquise,
            shapUrl: "/diamonds"
        },
        {
            shapName: "Heart",
            shapImgMain: heartImg,
            shapImgHover: heart,
            shapUrl: "/diamonds"
        },
        {
            shapName: "Pear",
            shapImgMain: pearImg,
            shapImgHover: pear,
            shapUrl: "/diamonds"
        },

    ]

    const shapSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <img src={ArrowlLeft} alt="left arrow Img" className='img-fluid' />,
        nextArrow: <img src={ArrowRight} alt="right arrow Img" className='img-fluid' />,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section className="rcs_ring_style_section vj_diasmond_shap_section">
                <Container className="vj_custome_container">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='vj_home_heading'>
                                <h2>Shop Diamonds By Shape</h2>
                                <p>The Art of Geometry</p>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} className='vj_shap_slider_desktop_view'>
                            <div className='vj_diamond_shap_outer_box'>
                                <ul>
                                    {
                                        DiamondShapData.map((dataItem) =>
                                            <li>
                                                <div className='vj_dimond_shap_box' onClick={() => { shopbyshape(dataItem.shapName) }}>
                                                    {/* <Image src={dataItem.shapImgHover} className="vj_diamond_shap_img_one" /> */}
                                                    <Image src={dataItem.shapImgMain} className="vj_diamond_shap_img_hover" />
                                                    <h6>{dataItem.shapName}</h6>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </Col>

                        <Col sm={12} md={12} lg={12} className='vj_shap_slider_mobile_view p-0'>
                            <Slider {...shapSettings} className='vj_shap_slider'>
                                {
                                    DiamondShapData.map((dataItem) =>
                                        <div className='vj_dimond_shap_box'>
                                            <Image src={dataItem.shapImgHover} className="vj_diamond_shap_img_one" />
                                            <Image src={dataItem.shapImgMain} className="vj_diamond_shap_img_hover" />
                                            <h6>{dataItem.shapName}</h6>
                                        </div>
                                    )
                                }

                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}


export default ShopDiamondShape;
