import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import GemstoneImg from '../../Assets/images/vishal-jogiya-img/gemstones.png'
import WeddingRingImg from '../../Assets/images/vishal-jogiya-img/wedding-ring.png'
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function Gemstones() {
    const history = useHistory();
    return (
        <>
            {/* <section className="rcs_gemstone_section" >
                <Container className="rcs_custom_home_container ">
     
                        <Col md={8} lg={7} className="ml-auto">
                            <div className="rcs_gemstone_content" data-aos="fade-up">
                                <h2> The World's Most Vivid Gemstones </h2>
                                <Button className="rcs_fill_button rcs_btn_rd-0  btn btn-primary" onClick={() => history.push('gemstones')}> Shop gemstones </Button>
                            </div>
                        </Col>
                   
                </Container>
            </section> */}
            <div className="vj_gemstone_wedding_section">
                <Container className="vj_custome_container ">
                    <Row>
                        <Col sm={12} md={6} lg={6}>
                            <div className="vj_gem_text_img_box">
                                <Image src={GemstoneImg} alt="Gemstone Image" className="img-fluid" />
                                <h6>Precious Gemstones</h6>
                                <NavLink to="/gemstones">Shop Now</NavLink>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <div className="vj_gem_text_img_box">
                                <Image src={WeddingRingImg} alt="Gemstone Image" className="img-fluid" />
                                <h6>Wedding Rings</h6>
                                <NavLink to="/jewelry/wedding-rings">Shop Now</NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    );
}

export default Gemstones;