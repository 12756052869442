import { createSlice } from "@reduxjs/toolkit";

export const bandBuilderSlice = createSlice({
  name: "bandBuilder",
  initialState: {
    byb: null,
  },
  reducers: {
    setBandBuilderData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setBandBuilderData } = bandBuilderSlice.actions;
export default bandBuilderSlice.reducer;