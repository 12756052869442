import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import RingImg1 from '../../Assets/images/vishal-jogiya-img/diamond-ring1.png'
import RingImg2 from '../../Assets/images/vishal-jogiya-img/diamond-ring2.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const DesignYourOwnEngRing = () => {
    const history = useHistory();
    return (
        <>
            <div className='vj_own_engagmernt_ring_section'>
                <Container className='vj_custome_container '>
                    <Row>
                        <Col xs={6} sm={6} md={3} lg={3} className='vj_ring_d_flex vj_design_order1'>
                            <div className='vj_ring_left_img'>
                                <Image src={RingImg1} alt="Ring Image" className='img-fluid' />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className='vj_design_order2'>
                            <div className='vj_start_btn_text_heading text-center'>
                                <h5>Design Your Own</h5>
                                <h2>Diamond Ring</h2>
                                <p>Design a Diamond Ring That Reflects Your Style and Personality</p>
                            </div>
                            <div className="vj_two_btn_ring_seting_and_diamond">
                                <div>
                                    <button className='vj_start_with_setting_btn' onClick={() => history.push("/ringsettings")}>Start With A Setting</button>
                                </div>
                                <div className='vj_ring_or'>
                                    <h6> or </h6>
                                </div>
                                <div>
                                    <button className='vj_start_with_diamond_btn' onClick={() => history.push("/diamonds")}>Start With A Diamond</button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={3} className='vj_ring_d_flex vj_design_order3'>
                            <div className='vj_ring_left_img'>
                                <Image src={RingImg2} alt="Ring Image" className='img-fluid' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default DesignYourOwnEngRing