import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Banner1 from '../../Assets/images/vishal-jogiya-img/home-banner.png'
import BannerMobile from '../../Assets/images/vishal-jogiya-img/home-banner-mobile.png'
import Slider from 'react-slick';
import ArrowlLeft from "../../Assets/images/vishal-jogiya-img/arrow-left.png";
import ArrowRight from "../../Assets/images/vishal-jogiya-img/arrow-right.png";
import { useMediaQuery } from 'react-responsive'

const BannerSlider = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  var SliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <img src={ArrowlLeft} alt="prev img" className='shadow-lg bg-white' />,
    nextArrow: <img src={ArrowRight} alt="next img" className='shadow-lg bg-white' />,
    // autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 10,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const data = [
    {
      bannerUrl: require('../../Assets/images/home/banner3.png'),
      bannerUrlMobile: require('../../Assets/images/home/banner3-mobile.png'),
      bannerText: "Fine Jewelry",
      bannerSubText: "Explore the latest Fine Jewelry",
      bannerLink: "/jewelry/fine-jewelry"
    },
    {
      bannerUrl: require('../../Assets/images/vishal-jogiya-img/home-banner.png'),
      bannerUrlMobile: require('../../Assets/images/vishal-jogiya-img/home-banner-mobile.png'),
      bannerText: "Engagement Rings",
      bannerSubText: "Uncover the Brilliance and Beauty",
      bannerLink: "/ringsettings"
    },
    {
      bannerUrl: require('../../Assets/images/home/banner2.jpg'),
      bannerUrlMobile: require('../../Assets/images/home/banner2-mobile.jpg'),
      bannerText: "Wedding Bands",
      bannerSubText: "Explore the latest Wedding Collection",
      bannerLink: "/jewelry/wedding-rings"
    },

    {
      bannerUrl: require('../../Assets/images/home/banner4.png'),
      bannerUrlMobile: require('../../Assets/images/home/banner4-mobile.jpg'),
      bannerText: "Gemstones",
      bannerSubText: "Design your ring with a stunning gemstone.",
      bannerLink: "/gemstones"
    },
    {
      bannerUrl: require('../../Assets/images/home/banner5.jpg'),
      bannerUrlMobile: require('../../Assets/images/home/banner5-mobile.jpg'),
      bannerText: "Custom Design",
      bannerSubText: "Tell Us Your Ideas",
      bannerLink: "/custom-design"
    },


  ];


  const history = useHistory();
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    //-----------------------------------Banner
    axios.get(base_url + `/home/slider`, {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          setBanner(response.data.data);
          localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])
  return (
    <>
      {/* {banner?.map((val, index) =>
        <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? val?.mobile_image : val?.image})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col md={9} className='m-auto'>
                  <div className={isMobile ? 'text-center' : val.text_direction_class}>
                    <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                    <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                    <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} variant="outline-dark">{val.btn_text}</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )} */}

      {/* <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? Banner1 : Banner1})` }}>
        <div className="rcs_hero_img">
          <Container fluid>
            <Row className='m-auto w-100'>
              <Col md={9} className='m-auto'>
                <div className={isMobile ? 'text-center' : 'text_direction_class'}>
                  <div>
                    <h1>Engagement Rings</h1>
                    <p>Uncover the Brilliance and Beauty</p>
                    <Button onClick={() => { history.push('/ringsettings') }} variant="outline-dark">Shop Engagement Rings</Button>
                  </div>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div> */}

      <Slider className="parentSl vj_main_banner_slider" {...SliderSettings}>
        {data.map((e, i) => (
          <div key={i}>
            <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isTabletOrMobile ? e.bannerUrlMobile : e.bannerUrl})` }}>
              <div className="rcs_hero_img">
                <Container fluid>
                  <Row className='m-auto w-100'>
                    <Col md={9} className='m-auto'>
                      <div className={isMobile ? 'text-center' : 'text_direction_class'}>
                        <div>
                          <h1>{e.bannerText}</h1>
                          <p>{e.bannerSubText}</p>
                          <Button onClick={() => { history.push(e.bannerLink) }} variant="outline-dark">{e.bannerLink === '/custom-design' ? "Create" : 'Shop'} {e.bannerText}</Button>
                        </div>

                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>

        ))}
      </Slider >
    </>
  )
}

export default BannerSlider;